import cx from 'classnames';
import React, {
    AnchorHTMLAttributes,
    ButtonHTMLAttributes,
    cloneElement,
    forwardRef,
    ReactElement,
} from 'react';

import { DesignSystemError } from '../../../types/FallbackFunction';

import style from './NavItem.module.css';

export type NavItemLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> &
    ContentProps & { isDesktop?: boolean };

export const NavItemLink = forwardRef<HTMLAnchorElement, NavItemLinkProps>(
    (
        { children, iconOnly, textOnly, isDesktop, iconLeading, iconTrailing, ...props },
        forwardedRef,
    ) => {
        return (
            <a
                {...props}
                ref={forwardedRef}
                className={cx(props.className, style.navItem, {
                    [style.iconOnly]: iconOnly === true,
                    [style.iconOnlyMobile]: iconOnly === 'mobileOnly',
                    [style.textOnlyDesktop]: textOnly === 'desktopOnly',
                    [style.hasIconLeading]: iconLeading,
                    [style.hasIconTrailing]: iconTrailing,
                    [style.isDesktop]: isDesktop,
                })}
            >
                <NavItemContent iconLeading={iconLeading} iconTrailing={iconTrailing}>
                    {children}
                </NavItemContent>
            </a>
        );
    },
);

export type NavItemButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
    ContentProps & { isDesktop?: boolean };

export const NavItemButton = forwardRef<HTMLButtonElement, NavItemButtonProps>(
    (
        { children, iconOnly, textOnly, isDesktop, iconLeading, iconTrailing, ...props },
        forwardedRef,
    ) => {
        return (
            <button
                type="button"
                {...props}
                ref={forwardedRef}
                className={cx(props.className, style.navItem, {
                    [style.iconOnly]: iconOnly === true,
                    [style.iconOnlyMobile]: iconOnly === 'mobileOnly',
                    [style.textOnlyDesktop]: textOnly === 'desktopOnly',
                    [style.hasIconLeading]: iconLeading,
                    [style.hasIconTrailing]: iconTrailing,
                    [style.isDesktop]: isDesktop,
                })}
            >
                <NavItemContent iconLeading={iconLeading} iconTrailing={iconTrailing}>
                    {children}
                </NavItemContent>
            </button>
        );
    },
);

type ContentProps = {
    children?: string;
    iconOnly?: boolean | 'mobileOnly';
    textOnly?: 'desktopOnly';
    iconLeading?: ReactElement<{ className?: string }>;
    iconTrailing?: ReactElement<{ className?: string }>;
};

function NavItemContent({ children, iconOnly, iconLeading, iconTrailing }: ContentProps) {
    if (iconOnly && typeof iconLeading === 'undefined') {
        throw new IconOnlyError('You need to specify a leading icon to display it as iconOnly');
    }

    return (
        <>
            {iconLeading
                ? cloneElement(iconLeading, {
                      ...iconLeading.props,
                      className: cx(iconLeading.props.className, style.iconLeading),
                  })
                : null}
            <span className={style.text}>{children}</span>
            {iconTrailing
                ? cloneElement(iconTrailing, {
                      ...iconTrailing.props,
                      className: cx(iconTrailing.props.className, style.iconTrailing),
                  })
                : null}
        </>
    );
}

export class IconOnlyError extends DesignSystemError {
    constructor(message: string) {
        super(message);
        this.name = 'IconOnlyError';
        Object.setPrototypeOf(this, IconOnlyError.prototype);
    }
}

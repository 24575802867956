import { useCallback, useState } from 'react';

import {
    AuthTrackingContext,
    OnLogin,
    OnLoginError,
    OnRegister,
    OnRegisterError,
} from '../../Login';
import { useSocialLogin } from '../../SocialLogin/SocialLogin';

const SCRIPT_ID = 'google-api';
const SCRIPT_SRC = 'https://accounts.google.com/gsi/client';

const clientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;

const googleLoginContext: {
    context: AuthTrackingContext;
    onLogin?: OnLogin;
    onLoginError?: OnLoginError;
    onRegister?: OnRegister;
    onRegisterError?: OnRegisterError;
} = {
    context: 'default',
    onLogin: undefined,
    onLoginError: undefined,
    onRegister: undefined,
    onRegisterError: undefined,
};

export function useGoogleScript(
    context: AuthTrackingContext,
    onLogin?: OnLogin,
    onLoginError?: OnLoginError,
    onRegister?: OnRegister,
    onRegisterError?: OnRegisterError,
) {
    const { connect } = useSocialLogin();
    const [isLoaded, setIsLoaded] = useState<boolean>(
        typeof window !== 'undefined' && window.google !== undefined,
    );

    const insertGoogleScript = useCallback((callback: () => void) => {
        const script = document.createElement('script');
        script.id = SCRIPT_ID;
        script.src = SCRIPT_SRC;
        script.async = true;
        script.defer = true;

        const scriptNode = document.getElementsByTagName('script')?.[0];
        if (scriptNode && scriptNode.parentNode) {
            scriptNode.parentNode.insertBefore(script, scriptNode);
        }

        script.onload = callback;
    }, []);

    return {
        isLoaded,
        load: useCallback(() => {
            return new Promise<WindowGoogle>((resolve) => {
                googleLoginContext.context = context;
                googleLoginContext.onLogin = onLogin;
                googleLoginContext.onLoginError = onLoginError;
                googleLoginContext.onRegister = onRegister;
                googleLoginContext.onRegisterError = onRegisterError;

                if (window.google) {
                    setIsLoaded(true);
                    return resolve(window.google as WindowGoogle);
                }

                insertGoogleScript(() => {
                    (window.google as WindowGoogle).accounts.id.initialize({
                        client_id: clientId as string,
                        callback: (response: { credential: string }) => {
                            connect(
                                'google',
                                response.credential,
                                googleLoginContext.context,
                                googleLoginContext.onLogin,
                                googleLoginContext.onLoginError,
                                googleLoginContext.onRegister,
                                googleLoginContext.onRegisterError,
                            );
                        },
                    });

                    setIsLoaded(true);

                    resolve(window.google as WindowGoogle);
                });
            });
        }, [
            insertGoogleScript,
            connect,
            context,
            onLogin,
            onLoginError,
            onRegister,
            onRegisterError,
        ]),
    };
}

export interface WindowGoogle {
    accounts: google.accounts;
}

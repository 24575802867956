import cx from 'classnames';
import { ComponentProps, ReactNode } from 'react';

import { Button, ButtonProps } from '../form/button/Button';
import { IconCheckMarkRounded } from '../icons/CheckMarkRounded';
import { IconExclamationMarkRounded } from '../icons/ExclamationMarkRounded';
import { IconExclamationMarkTriangle } from '../icons/ExclamationMarkTriangle';
import { IconInformationRounded } from '../icons/InformationRounded';

import style from './Alert.module.css';

type Props = ComponentProps<'div'> & {
    type: 'information' | 'success' | 'warning' | 'error';
    message: string | ReactNode;
    title?: string;
    buttonProps?: Omit<ButtonProps, 'priority' | 'alert'>;
};

const ICONS_BY_TYPE = {
    information: IconInformationRounded,
    success: IconCheckMarkRounded,
    warning: IconExclamationMarkTriangle,
    error: IconExclamationMarkRounded,
};

export function Alert({ message, title, type, className, buttonProps, ...props }: Props) {
    const Icon = ICONS_BY_TYPE[type];

    return (
        <div
            {...props}
            className={cx('font-body-regular', style.alert, className, {
                [style.information]: type === 'information',
                [style.success]: type === 'success',
                [style.warning]: type === 'warning',
                [style.error]: type === 'error',
                [style.withButton]: Boolean(buttonProps),
            })}
        >
            <div className={style.content}>
                <Icon
                    className={cx(style.icon, {
                        [style.informationIcon]: type === 'information',
                        [style.successIcon]: type === 'success',
                        [style.warningIcon]: type === 'warning',
                        [style.errorIcon]: type === 'error',
                    })}
                />
                <div className={style.textWrapper}>
                    {title ? <div className="font-body-bold">{title}</div> : null}
                    {message}
                </div>
            </div>

            {buttonProps ? (
                // @ts-expect-error - ButtonProps type mismatch with polymorphic component
                <Button {...buttonProps} priority="text" alert={type} />
            ) : null}
        </div>
    );
}

import { useEffect } from 'react';

import { logger } from '../../../../core/Log/logger';
import { AuthTrackingContext, LoginCallbacks } from '../../Login';

import { useAppleScript } from './Apple';

type Props = {
    height?: number;
    width?: number;
    context: AuthTrackingContext;
} & LoginCallbacks;

export function AppleButton({
    height,
    width,
    onLogin,
    onRegister,
    context,
    onLoginError,
    onRegisterError,
}: Props) {
    const { load, init } = useAppleScript(
        context,
        onLogin,
        onLoginError,
        onRegister,
        onRegisterError,
    );

    useEffect(() => {
        load()
            .then((appleId) => {
                init(appleId);
            })
            .catch((error) => {
                logger.error('Apple SocialLogin could not init', {}, error);
            });
    }, [init, load]);

    return (
        <div
            // eslint-disable-next-line react/forbid-dom-props
            style={{
                height,
                width,
            }}
            id="appleid-signin"
            data-color="white"
            data-border="true"
            data-type="sign in"
        />
    );
}

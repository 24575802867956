import cx from 'classnames';

import { useRouter } from 'next/compat/router';

import { useIntl } from 'react-intl';

import { useUrlGenerator } from '../../shared/hooks/useUrlGenerator';

import { AuthTrackingContext } from './Login';
import style from './Register.module.css';

type RegisterProps = {
    context: AuthTrackingContext;
};
export function Register({ context }: RegisterProps) {
    const router = useRouter();
    const { generatePath } = useUrlGenerator();
    const { formatMessage } = useIntl();
    return (
        <div className={cx('font-xs-regular', style.register)}>
            {formatMessage({
                id: 'global.component.login.newMember',
            })}
            <a
                className="link"
                href={`${generatePath('register')}?redirect_url=${encodeURIComponent(
                    router?.asPath ?? '',
                )}&fromContext=${context}`}
            >
                {formatMessage({
                    id: 'global.component.login.register',
                })}
            </a>
        </div>
    );
}

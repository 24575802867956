import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Modal } from '../../design-system/components/modal/Modal';
import { UseModalState } from '../../design-system/components/modal/useModal';
import { Separator } from '../../design-system/components/separator/Separator';

import { AuthTrackingContext } from './Login';
import style from './LoginModal.module.css';
import { EvaneosLoginForm } from './Provider/Evaneos/EvaneosLoginForm';
import { Register } from './Register';
import { SocialLogin } from './SocialLogin/SocialLogin';

export type LoginModalProps = UseModalState & {
    onLogin?: () => void;
    context: AuthTrackingContext;
};

export function LoginModal(props: LoginModalProps) {
    const { formatMessage } = useIntl();
    const { onLogin: onLoginProps, close } = props;
    const onLogin = useCallback(() => {
        if (typeof onLoginProps === 'function') {
            onLoginProps();
        }

        close();
    }, [onLoginProps, close]);

    return (
        <Modal
            {...props}
            size="small"
            title={formatMessage({
                id: 'global.component.login.title',
            })}
        >
            <div className={style.login}>
                <div className={style.loginWrapper}>
                    <SocialLogin context={props.context} onLogin={onLogin} />
                    <Separator
                        wording={formatMessage({ id: 'global.component.login.seperatorLabel' })}
                    />
                    <EvaneosLoginForm context={props.context} onLogin={onLogin} />
                    <Register context={props.context} />
                </div>
            </div>
        </Modal>
    );
}

import { useEffect, useRef } from 'react';

import { logger } from '../../../../core/Log/logger';
import { AuthTrackingContext, LoginCallbacks } from '../../Login';

import { useGoogleScript, WindowGoogle } from './Google';

type Props = {
    width: number;
    height: number;
    context: AuthTrackingContext;
} & LoginCallbacks;

export function GoogleButton({
    width,
    onLogin,
    onRegister,
    context,
    onLoginError,
    onRegisterError,
    height,
}: Props) {
    const ref = useRef<HTMLDivElement>(null);
    const { load, isLoaded } = useGoogleScript(
        context,
        onLogin,
        onLoginError,
        onRegister,
        onRegisterError,
    );

    useEffect(() => {
        load().catch((error) => {
            logger.error('Google SocialLogin could not init', {}, error);
        });
    }, [load]);

    useEffect(() => {
        if (isLoaded && ref.current && window.google) {
            (window.google as WindowGoogle).accounts.id.renderButton(ref.current, {
                width,
                theme: 'outline',
                size: 'large',
                logo_alignment: 'left',
            });
        }
    }, [isLoaded, width]);

    return (
        <div
            // eslint-disable-next-line react/forbid-dom-props
            style={{ height }}
            ref={ref}
        ></div>
    );
}

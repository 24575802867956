import { logger } from '../../core/Log/logger';
import { Currency } from '../../resources/currency/domain/types/currency';

export const UserPreferredCurrency = {
    key: 'userPreferredCurrency',
    get(): Currency | null {
        try {
            return localStorage.getItem(this.key) as Currency | null;
        } catch (error) {
            logger.error('Error while getting user currency from localStorage', {}, error);
        }
        return null;
    },
    set(currency: Currency): void {
        try {
            localStorage.setItem(this.key, currency);
        } catch (error) {
            logger.error('Error while setting user currency in localStorage', {}, error);
        }
    },
};

import { useCallback, useEffect } from 'react';

import { Locale, useLocale } from '../../../../core/i18n/Locale';
import { useUrlGenerator } from '../../../../shared/hooks/useUrlGenerator';
import {
    AuthTrackingContext,
    OnLogin,
    OnLoginError,
    OnRegister,
    OnRegisterError,
} from '../../Login';
import { useSocialLogin } from '../../SocialLogin/SocialLogin';

const SCRIPT_ID = 'google-api';

const clientId = process.env.NEXT_PUBLIC_APPLE_CLIENT_ID;

export const getScriptSrc = (locale: Locale) => {
    const transformedLocale = locale
        .replace('de-CH', 'de-DE')
        .replace('en-CA', 'en-US')
        .replace('-', '_');

    const scriptSrc = `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${transformedLocale}/appleid.auth.js`;

    return scriptSrc;
};

export function useAppleScript(
    context: AuthTrackingContext,
    onLogin?: OnLogin,
    onLoginError?: OnLoginError,
    onRegister?: OnRegister,
    onRegisterError?: OnRegisterError,
) {
    const { connect } = useSocialLogin();
    const { generateUrl } = useUrlGenerator();
    const { locale } = useLocale();

    const insertAppleScript = useCallback(
        (callback: () => void) => {
            const script = document.createElement('script');
            script.id = SCRIPT_ID;
            script.src = getScriptSrc(locale);
            script.async = true;
            script.defer = true;

            const scriptNode = document.getElementsByTagName('script')?.[0];
            if (scriptNode && scriptNode.parentNode) {
                scriptNode.parentNode.insertBefore(script, scriptNode);
            }

            script.onload = callback;
        },
        [locale],
    );

    const load = useCallback(() => {
        return new Promise<AppleSignInAPI.AppleID>((resolve) => {
            function handleScriptInserted() {
                resolve(window.AppleID);
            }

            // window.AppleID is set once the script is inserted in the HTML ...
            if (typeof window !== 'undefined' && window.AppleID !== undefined) {
                handleScriptInserted();
                return;
            }

            // ... so that script will be inserted only ONCE
            insertAppleScript(handleScriptInserted);
        });
    }, [insertAppleScript]);

    useEffect(() => {
        const handleAppleIdSignInSuccess = (event: Event) => {
            const customEvent = event as CustomEvent<AppleSignInAPI.SignInResponseI>;

            const { user } = customEvent.detail;

            connect(
                'apple',
                customEvent.detail.authorization.code,
                context,
                onLogin,
                onLoginError,
                onRegister,
                onRegisterError,
                {
                    email: user?.email,
                    firstname: user?.name.firstName,
                    lastname: user?.name.lastName,
                },
            );
        };

        document.addEventListener('AppleIDSignInOnSuccess', handleAppleIdSignInSuccess);

        return () => {
            document.removeEventListener('AppleIDSignInOnSuccess', handleAppleIdSignInSuccess);
        };
    }, [connect, context, onLogin, onLoginError, onRegister, onRegisterError]);

    const init = useCallback(
        (appleId: AppleSignInAPI.AppleID) => {
            appleId.auth.init({
                clientId,
                scope: 'name email',
                redirectURI: generateUrl('provider_login'),
                usePopup: true,
            });
        },
        [generateUrl],
    );

    return {
        load,
        init,
    };
}

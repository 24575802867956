'use client';

import { createContext, PropsWithChildren, useEffect, useState } from 'react';

import { logger } from '../../../core/Log/logger';
import { getCurrencies } from '../../../resources/currency/domain/operations/getCurrencies';
import { Currencies, Currency } from '../../../resources/currency/domain/types/currency';
import { UserPreferredCurrency } from '../../services/UserPreferredCurrency';
import { useCurrentSite } from '../currentSite/useCurrentSite';

interface CurrencyContextValue {
    currencies: Currencies;
    currentCurrency: Currency;
    changeCurrentCurrency: (currency: Currency) => void;
}

export const CurrencyContext = createContext<CurrencyContextValue | null>(null);

export const CurrencyProvider = ({ children }: PropsWithChildren) => {
    const { currentSite } = useCurrentSite();
    const defaultCurrency = currentSite.currency;

    const [value, setValue] = useState({
        currencies: getCurrencies(),
        currentCurrency: defaultCurrency,
        changeCurrentCurrency: (currency: Currency) => {
            logger.info(`User changed its currency`, {
                oldCurrency: value.currentCurrency,
                newCurrency: currency,
            });

            UserPreferredCurrency.set(currency);

            setValue({
                ...value,
                currentCurrency: currency,
            });
        },
    });

    useEffect(() => {
        const userPreferredCurrency = UserPreferredCurrency.get();

        if (!userPreferredCurrency) {
            return;
        }

        setValue((previousValue) => {
            return {
                ...previousValue,
                currentCurrency: userPreferredCurrency,
            };
        });
    }, []);

    return <CurrencyContext.Provider value={value}>{children}</CurrencyContext.Provider>;
};

import { SVGAttributes } from 'react';

type Props = SVGAttributes<SVGElement>;

export function IconChevronDown(props: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            data-testid="IconChevronDown"
            {...props}
        >
            <path d="M15.88 10.2901L12 14.1701L8.11998 10.2901C7.72998 9.90006 7.09998 9.90006 6.70998 10.2901C6.31998 10.6801 6.31998 11.3101 6.70998 11.7001L11.3 16.2901C11.69 16.6801 12.32 16.6801 12.71 16.2901L17.3 11.7001C17.69 11.3101 17.69 10.6801 17.3 10.2901C16.91 9.91006 16.27 9.90006 15.88 10.2901Z" />
        </svg>
    );
}

import cx from 'classnames';

import style from './Separator.module.css';

type Props = {
    wording?: string;
    className?: string;
};

export function Separator({ wording, className }: Props) {
    return (
        <div className={cx(style.separator, className)}>
            {wording ? <span className={style.separatorWording}>{wording}</span> : null}
        </div>
    );
}

import Cookies from 'js-cookie';

import { getDefaultSite } from '../../core/Site/Site';
import { trackCustomEvent } from '../../core/Tracking/GoogleAnalytics';
import { AuthTrackingContext } from '../../domains/Login/Login';
import { getUserHash } from '../../resources/user/domain/operations/getUserHash';
import { setUserTrackingIdCookie } from '../../resources/user/domain/operations/setUserTrackingIdCookie';
import { generateUrl } from '../../shared/utilities/routing/routing';

export const SUBSCRIBE_MODE_DEFAULT = 1;
export const SUBSCRIBE_MODE_WISHLIST = 2;
export const SUBSCRIBE_MODE_TRAVELERS_LINKING = 3;
export const SUBSCRIBE_MODE_FOOTER = 4;
export const SUBSCRIBE_MODE_OVERLAY = 5;
export const SUBSCRIBE_MODE_LANDING_PAGE = 6;
export const SUBSCRIBE_MODE_NAVIGATION = 7;
export const SUBSCRIBE_MODE_EXTERNAL = 8;
export const SUBSCRIBE_MODE_CO_TRAVELER = 9;
export const SUBSCRIBE_MODE_QUOTE = 10;
export const SUBSCRIBE_MODE_LEAD_NURTURING = 11;
export const SUBSCRIBE_MODE_CO_PLANNER = 12;

export function AuthTrackingContextToSubscribeMod(context: AuthTrackingContext) {
    switch (context) {
        case 'default':
            return SUBSCRIBE_MODE_DEFAULT;
        case 'wishlist':
            return SUBSCRIBE_MODE_WISHLIST;
        case 'travelers-linking':
            return SUBSCRIBE_MODE_TRAVELERS_LINKING;
        case 'footer':
            return SUBSCRIBE_MODE_FOOTER;
        case 'overlay':
            return SUBSCRIBE_MODE_OVERLAY;
        case 'landing-page':
            return SUBSCRIBE_MODE_LANDING_PAGE;
        case 'navigation':
            return SUBSCRIBE_MODE_NAVIGATION;
        case 'external':
            return SUBSCRIBE_MODE_EXTERNAL;
        case 'co-traveler':
            return SUBSCRIBE_MODE_CO_TRAVELER;
        case 'quoteform':
            return SUBSCRIBE_MODE_QUOTE;
        case 'lead-nurturing':
            return SUBSCRIBE_MODE_LEAD_NURTURING;
        case 'co-planner':
            return SUBSCRIBE_MODE_CO_PLANNER;
        default:
            return SUBSCRIBE_MODE_DEFAULT;
    }
}

type AddUserAndSilverpopParams = {
    email: string;
    subscribe_mode: number;
    subscribe_mode_detail: string | null;
    lastname?: string;
    firstname?: string;
    confirm?: boolean;
    original_quote_id?: number;
    campaign_source?: string;
    campaign_medium?: string;
    campaign_term?: string;
    campaign_name?: string;
    acquisition_tags?: string[];
    newsletter_tickbox?: boolean;
    destination_slug?: string | null;
};

type MemberProps = {
    isNew: boolean;
    subscribeMode: AddUserAndSilverpopParams['subscribe_mode'] | string;
    subscribeModeDetail: AddUserAndSilverpopParams['subscribe_mode_detail'];
    acquisitionTags?: AddUserAndSilverpopParams['acquisition_tags'];
    email: string;
};

type AddUserAndSilverpopOptions = {
    requestPath?: string | null;
};

export async function trackOptin(member: MemberProps) {
    const { userMD5 } = await getUserHash(member);

    trackCustomEvent({
        event: 'new_optin',
        member: {
            ...member,
            email: userMD5,
        },
    });

    await setUserTrackingIdCookie(member);
}

/**
 * Call a ajax endpoint to save a new user and add it to Silverpop
 * Used in Betty and Pyrite and Nextjs
 */
export function addUserAndSilverpop(
    paramsJson: AddUserAndSilverpopParams,
    { requestPath }: AddUserAndSilverpopOptions = {},
) {
    const { location } = window;
    const _params = {
        email: paramsJson.email,
        subscribe_mode: paramsJson.subscribe_mode,
        subscribe_mode_detail: paramsJson.subscribe_mode_detail || '',
        lastname: paramsJson.lastname,
        firstname: paramsJson.firstname,
        confirm: paramsJson.confirm,
        original_quote_id: paramsJson.original_quote_id,
        campaign_source: paramsJson.campaign_source,
        campaign_medium: paramsJson.campaign_medium,
        campaign_term: paramsJson.campaign_term,
        campaign_name: paramsJson.campaign_name,
        acquisition_tags: paramsJson.acquisition_tags || [],
        newsletter_tickbox: paramsJson.confirm || true,
        destination_slug: paramsJson.destination_slug || null,
    };

    const _requestPath = requestPath || generateUrl('register_source', getDefaultSite().locale);
    const _endpointUrl = `${location.protocol}//${location.hostname}${_requestPath}`;

    function setCampaignFromCookies(): void {
        const cookies = Cookies.get('utm_params');
        const utm_params = cookies ? JSON.parse(cookies) : null;

        if (!utm_params) {
            return;
        }

        _params.campaign_source = utm_params.campaign_source;
        _params.campaign_medium = utm_params.campaign_medium;
        _params.campaign_term = utm_params.campaign_term;
        _params.campaign_name = utm_params.campaign_name;
    }

    /**
     * Send all parameters to the endpoint
     */
    function send() {
        return fetch(_endpointUrl, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'x-requested-with': 'XMLHttpRequest',
            },
            body: JSON.stringify(_params),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(async (data: { status: number; [key: string]: unknown }) => {
                if (data.status === 1) {
                    await trackOptin({
                        isNew: !data.memberExists,
                        subscribeMode: _params.subscribe_mode,
                        subscribeModeDetail: _params.subscribe_mode_detail,
                        acquisitionTags: _params.acquisition_tags,
                        email: _params.email,
                    });
                }
                return data;
            });
    }

    return {
        send,
        // It actually SETS campaign from cookies, but since addUserAndSilverpop is exposed in window.gtm,
        // we cannot rename this method because it is used.
        getCampaignFromCookies: setCampaignFromCookies,
    };
}

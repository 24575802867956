import { useCallback } from 'react';

import { trackOptin } from '../../../components/Newsletter/NewsletterService';
import { logger } from '../../../core/Log/logger';
import { useAmplitude, useTrackEventAmplitude } from '../../../core/Tracking/Amplitude/Amplitude';
import { useUrlGenerator } from '../../../shared/hooks/useUrlGenerator';
import { useUser } from '../../../shared/providers/user/useUser';
import {
    AuthTrackingContext,
    LoginCallbacks,
    OnLogin,
    OnLoginError,
    OnRegister,
    OnRegisterError,
} from '../Login';
import { AppleButton } from '../Provider/Apple/AppleButton';
import { GoogleButton } from '../Provider/Google/GoogleButton';
import { LoginProviderName, SocialLoginProviderName } from '../Provider/Provider';

import style from './SocialLogin.module.css';

type providerName = 'apple' | 'google';

type Props = {
    width?: number;
    context: AuthTrackingContext;
} & LoginCallbacks;

type RegisterResponseBody = {
    redirect_url: string;
    success: boolean;
    member: {
        email: string;
        newsletterSubscription: boolean;
    };
};

const LOGIN_WIDTH = 288; // Due to a fixed width of social login

export function useSocialLogin() {
    const { fetchUser } = useUser();
    const { generatePath } = useUrlGenerator();

    const connect = useCallback(
        (
            providerName: providerName,
            token: string,
            context: AuthTrackingContext,
            onLogin?: OnLogin,
            onLoginError?: OnLoginError,
            onRegister?: OnRegister,
            onRegisterError?: OnRegisterError,
            extra?: {
                email?: string;
                firstname?: string;
                lastname?: string;
            },
        ) => {
            const formData = new FormData();

            formData.append('providerName', providerName);
            formData.append('providerToken', token);
            formData.append('fromContext', context);

            if (extra?.email) {
                formData.append('email', extra.email);
            }

            if (extra?.firstname) {
                formData.append('firstname', extra.firstname);
            }

            if (extra?.lastname) {
                formData.append('lastname', extra.lastname);
            }

            async function tryRegister() {
                try {
                    const response = await fetch(generatePath('provider_register'), {
                        method: 'POST',
                        body: formData,
                        credentials: 'same-origin',
                        headers: {
                            'x-requested-with': 'XMLHttpRequest',
                        },
                    });

                    if (!response.ok) {
                        throw new Error(
                            `SocialLogin register request failed with status ${response.status}`,
                        );
                    }

                    const { member }: RegisterResponseBody = await response.json();

                    if (onRegister) {
                        onRegister(providerName, { email: member.email });
                    }

                    await fetchUserOnRegister();
                } catch (error) {
                    if (onRegisterError) {
                        onRegisterError(providerName);
                    }

                    logger.error(
                        'SocialLogin register failed',
                        { providerName, fromContext: context },
                        error,
                    );
                }
            }

            async function fetchUserOnRegister() {
                // Keep behaviour after refactoring `fetchUser` function:
                // Ignore if user fetch failed because it was caught before so we didn't know,
                // but log it so we know it happened.
                try {
                    await fetchUser();

                    if (onLogin) {
                        onLogin(providerName);
                    }

                    if (!window.location.pathname.includes('expressquote')) {
                        document.dispatchEvent(
                            new CustomEvent('social-login-register', { detail: { context } }),
                        );
                    }
                } catch (error) {
                    logger.error(
                        'SocialLogin user fetch failed after register',
                        { providerName, fromContext: context },
                        error,
                    );
                }
            }

            async function fetchUserOnLogin() {
                // Keep behaviour after refactoring `fetchUser` function:
                // Ignore if user fetch failed because it was caught before so we didn't know,
                // but log it so we know it happened.
                try {
                    await fetchUser();

                    if (onLogin) {
                        onLogin(providerName);
                    }
                } catch (error) {
                    logger.error(
                        'SocialLogin user fetch failed after login',
                        { providerName, fromContext: context },
                        error,
                    );
                }
            }

            fetch(generatePath('provider_login'), {
                method: 'POST',
                body: formData,
                credentials: 'same-origin',
                headers: {
                    'x-requested-with': 'XMLHttpRequest',
                },
            })
                .then((response) => {
                    if (response.status === 401) {
                        void tryRegister();
                        return;
                    }

                    if (!response.ok) {
                        throw new Error(
                            `SocialLogin login request failed with status ${response.status}`,
                        );
                    }

                    return fetchUserOnLogin();
                })
                .catch((error) => {
                    if (onLoginError) {
                        onLoginError(providerName);
                    }

                    logger.error(
                        'SocialLogin login failed',
                        {
                            providerName,
                            fromContext: context,
                        },
                        error,
                    );
                });
        },
        [fetchUser, generatePath],
    );

    return {
        connect,
    };
}

type RegisterOptions = {
    email?: string;
};

export function SocialLogin({
    width = LOGIN_WIDTH,
    onLogin: onLoginProps,
    onRegister: onRegisterProps,
    context,
    onRegisterError,
    onLoginError,
}: Props) {
    const { setIsConnected } = useAmplitude();
    const { trackEvent } = useTrackEventAmplitude();

    const onRegister = useCallback(
        (loginProviderName: SocialLoginProviderName, options?: RegisterOptions) => {
            if (onRegisterProps) {
                onRegisterProps(loginProviderName, options);
            }

            void trackOptin({
                isNew: true,
                subscribeMode: '',
                subscribeModeDetail: '',
                email: options?.email ?? '',
            });

            trackEvent((ampli, defaultProperties) => {
                ampli.authenticationSucceed({
                    ...defaultProperties,
                    already_existing: false,
                    connection_source: loginProviderName,
                });
            });
        },
        [onRegisterProps, trackEvent],
    );

    const onLogin = useCallback(
        (loginProviderName: LoginProviderName) => {
            if (typeof onLoginProps === 'function') {
                onLoginProps(loginProviderName);
            }

            trackEvent((ampli, defaultProperties) => {
                ampli.authenticationSucceed({
                    ...defaultProperties,
                    already_existing: true,
                    connection_source:
                        loginProviderName === 'evaneos' ? 'email' : loginProviderName,
                });
            });

            setIsConnected(true);
        },
        [onLoginProps, setIsConnected, trackEvent],
    );
    return (
        <div className={style.container}>
            <GoogleButton
                context={context}
                onLogin={onLogin}
                onRegister={onRegister}
                onRegisterError={onRegisterError}
                onLoginError={onLoginError}
                width={width}
                height={40}
            />
            <AppleButton
                context={context}
                onLogin={onLogin}
                onRegister={onRegister}
                onRegisterError={onRegisterError}
                onLoginError={onLoginError}
                width={width}
                height={40}
            />
        </div>
    );
}

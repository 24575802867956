import cx from 'classnames';

import { forwardRef, InputHTMLAttributes, useEffect, useState } from 'react';

import style from './Checkbox.module.css';

type Props = {
    label: string;
    checkboxProps: Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
        'data-testid'?: string;
    };
    className?: string;
};

export const Checkbox = forwardRef<HTMLInputElement, Props>(
    ({ label, checkboxProps, className }, ref) => {
        const [isChecked, setIsChecked] = useState(checkboxProps.checked);

        useEffect(() => {
            setIsChecked(checkboxProps.checked);
        }, [checkboxProps.checked]);

        return (
            <label className={cx(style.label, 'font-xs-regular', className)}>
                <div className={style.inputWrapper}>
                    <input
                        {...checkboxProps}
                        ref={ref}
                        onChange={(event) => {
                            const isChecked = event.target.checked;

                            if (checkboxProps.onChange) {
                                checkboxProps.onChange(event);
                            }
                            setIsChecked(isChecked);
                        }}
                        checked={isChecked}
                        type="checkbox"
                    />
                    <div className={style.checkmark} />
                </div>
                {label}
            </label>
        );
    },
);

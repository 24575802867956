import cx from 'classnames';
import { useRouter } from 'next/compat/router';
import { FormEvent, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { logger } from '../../../../core/Log/logger';
import {
    useAmplitude,
    useTrackEventAmplitude,
} from '../../../../core/Tracking/Amplitude/Amplitude';
import { Alert } from '../../../../design-system/components/alert/Alert';
import { Button } from '../../../../design-system/components/form/button/Button';
import { Checkbox } from '../../../../design-system/components/form/checkbox/Checkbox';
import { Input } from '../../../../design-system/components/form/input/Input';
import { DotsLoader } from '../../../../design-system/components/loader/dots/DotsLoader';
import { useUrlGenerator } from '../../../../shared/hooks/useUrlGenerator';
import { useUser } from '../../../../shared/providers/user/useUser';
import { AuthTrackingContext, LoginCallbacks } from '../../Login';

import { LoginProviderName } from '../Provider';

import style from './EvaneosLoginForm.module.css';

interface FormElements extends HTMLFormControlsCollection {
    email: HTMLInputElement;
    password: HTMLInputElement;
    rememberme: HTMLInputElement;
}

interface LoginFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

type Props = {
    context: AuthTrackingContext;
} & LoginCallbacks;

const PROVIDER_NAME = 'evaneos';

export function EvaneosLoginForm({ onLogin: onLoginProps, onLoginError }: Props) {
    const router = useRouter();
    const { generatePath } = useUrlGenerator();
    const { fetchUser } = useUser();
    const { formatMessage } = useIntl();
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const { setIsConnected } = useAmplitude();
    const { trackEvent } = useTrackEventAmplitude();

    const submitPath = generatePath('login');

    const onLogin = useCallback(
        (loginProviderName: LoginProviderName) => {
            if (typeof onLoginProps === 'function') {
                onLoginProps(loginProviderName);
            }

            trackEvent((ampli, defaultProperties) => {
                ampli.authenticationSucceed({
                    ...defaultProperties,
                    already_existing: true,
                    connection_source: 'email',
                });
            });

            setIsConnected(true);
        },
        [onLoginProps, setIsConnected, trackEvent],
    );

    const onSubmit = (e: FormEvent<LoginFormElement>) => {
        e.preventDefault();

        const email = e.currentTarget.elements.email.value;
        const password = e.currentTarget.elements.password.value;
        const rememberMe = e.currentTarget.elements.rememberme.checked;

        const formData = new FormData();

        formData.append('_username', email);
        formData.append('_password', password);
        formData.append('_remember_me', Number(rememberMe).toString());

        setSubmitting(true);

        fetch(submitPath, {
            method: 'POST',
            body: formData,
            credentials: 'same-origin',
            headers: {
                'x-requested-with': 'XMLHttpRequest',
            },
        })
            .then((response: Response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(() => {
                return fetchUser()
                    .catch((error) => {
                        // Keep behaviour after refactoring `fetchUser` function:
                        // Ignore if user fetch failed because it was caught before so we didn't know,
                        // but log it so we know it happened.
                        logger.error(
                            'EvaneosLoginForm on login, fetch user failed',
                            {
                                providerName: PROVIDER_NAME,
                            },
                            error,
                        );
                    })
                    .then(() => {
                        onLogin(PROVIDER_NAME);
                    });
            })
            .catch((response: Response) => {
                if (onLoginError) {
                    onLoginError(PROVIDER_NAME);
                }

                if (response.status === 429) {
                    setError(formatMessage({ id: 'global.component.login.errorTooManyAttempts' }));
                    return;
                }

                setError(
                    formatMessage({
                        id: 'global.component.login.error',
                    }),
                );
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <form onSubmit={onSubmit} method="POST" action={submitPath}>
            {error ? <Alert className={style.item} type="error" message={error} /> : null}
            <Input
                className={style.item}
                label={formatMessage({
                    id: 'global.component.login.emailLabel',
                })}
                inputProps={{
                    type: 'email',
                    name: 'email',
                    defaultValue: '',
                }}
            />
            <Input
                className={style.item}
                label={formatMessage({
                    id: 'global.component.login.passwordLabel',
                })}
                inputProps={{
                    type: 'password',
                    name: 'password',
                    defaultValue: '',
                }}
            />
            <div className={cx('font-xs-regular', style.rememberWrapper)}>
                <Checkbox
                    checkboxProps={{
                        name: 'rememberme',
                        value: '1',
                        checked: true,
                    }}
                    label={formatMessage({
                        id: 'global.component.login.rememberMe',
                    })}
                />
                <a
                    className="link"
                    href={`${generatePath('lost_password')}?redirect_url=${encodeURIComponent(
                        router?.asPath ?? '',
                    )}`}
                >
                    {formatMessage({
                        id: 'global.component.login.forgotPassword',
                    })}
                </a>
            </div>
            <Button className={style.submit} type="submit" isFullWidth>
                {formatMessage({
                    id: 'global.component.login.submitLabel',
                })}
                {submitting ? <DotsLoader /> : null}
            </Button>
        </form>
    );
}
